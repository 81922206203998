export default [
  {
    route: 'my-publications',
    title: 'My Publications',
    icon: 'GridIcon',
    action: 'read',
    resource: 'ACL',
  },
  { 
    header: ':p_name',
    action: 'read',
    resource: 'ACL',
  },
  {
    route: 'production-admin',
    title: 'Production',
    icon: 'LayoutIcon',
    action: 'admin',
    resource: 'ACL',
  },
  {
    title: 'Reports',
    icon: 'LayersIcon',
    route: { name: 'reports', params: { issn: ' ' } },
    action: 'admin',
    resource: 'ACL',
  },
  {
    route: 'taxonomy',
    title: 'Categories',
    icon: 'TagIcon',
    action: 'admin',
    resource: 'ACL',
  },
  {
    route: 'issue-intake',
    title: 'Issue Intake',
    icon: 'BriefcaseIcon',
    action: 'admin',
    resource: 'ACL',
    appendDynamicItemsHere: 'y'
  }
]
