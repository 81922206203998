<template>
  <ul v-if="menuItemsPopulated">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in menuItems"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>

import publicationApi from '@/dl_pubmill/apis/publication'

import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // Athenable-Dependency here
      menuItems: null,
      menuItemsPopulated: false,
      publicationId: null,
      publicationShortName: null,
      publicationIssueData: null
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  watch: {
    // Athenable-Dependency here
    '$store.state.pubmillGlobalStore.currentPublicationObject.id': {
      immediate: true,
      handler() {
        if (!this.$store.state.pubmillGlobalStore.currentPublicationObject) return
        console.log('[**VerticalNaVMenu.vue.WATCH(pubmillGlobalStore.currentPublicationObject)**]')
        this.hideMenu = true
        this.getPublication(this.$store.state.pubmillGlobalStore.currentPublicationObject.id)
      },
    },
  },
  methods: {
    resolveVariableParamsInUrl(route) {
      // see also navigation/ vertical / pubmill.js and router/routes/ dl-pubmill-routes.js
      if (route.params.issn) {
        route.params.issn = this.publicationId
      }
      if (route.params.date) {
        const today = new Date()
        const dd = String(today.getDate()).padStart(2, '0')
        const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
        const yyyy = String(today.getFullYear())
        route.params.date = yyyy.concat('-', mm, '-', dd)
      }

    },
    pubmillOnPublicationNavRefresh() {
      //console.log('nnnnnnnn pubmillOnPublicationNavRefresh')
      this.menuItems = []
      let navItem = null

      const keys = Object.keys(this.items)
      for (let i = 0; i < keys.length; i++) {
        navItem = this.items[keys[i]]
        // console.log('nnnnnnnn navItem', navItem)
        if (navItem.route && navItem.route.params) {
          this.resolveVariableParamsInUrl(navItem.route)
        }
        if (navItem.children) { 
          for (let j = 0; j < navItem.children.length; j++) {
            const subItem = navItem.children[j]
            //console.log('nnnnnnnn subItem', subItem)
            if (subItem.route && subItem.route.params) {
              this.resolveVariableParamsInUrl(subItem.route)
            }
          }
        }
        if (navItem.header && navItem.header === ':p_name') {
          //console.log('nnnppp  navItem', navItem)
          navItem.header = this.publicationShortName
          this.menuItems.push(navItem)
          continue
        } 
        //console.log('nnnppp1  navItem', navItem)
        if (navItem.appendDynamicItemsHere && this.publicationIssueData.currentIssueData) {
          this.menuItems.push(navItem)
          
          const { currentIssueData } = this.publicationIssueData
          const { vol } = currentIssueData
          const { iss } = currentIssueData
          
          //Current Issue inserted here
          const currentIssNavItem = {
            route: { 
              name: 'current-issue-admin',  
              params: { 
                issn: this.publicationId, 
                vol,
                issue: iss, 
              },
            },
            title: 'Current Issue',
            icon: 'BookIcon',
            action: 'read',
            resource: 'ACL',
          }
          this.menuItems.push(currentIssNavItem)

          //Populate Archive menu here
          const gvItem = {
            title: 'Issue Archive', 
            icon: 'ArchiveIcon',
            children: []
          }
 
          Object.keys(this.publicationIssueData.volumes).forEach(issDataKey => {
            const issData = this.publicationIssueData.volumes[issDataKey]
            // console.log('uuuuuuuuu', issData.vol,  issData.year)
            const volYear = issData.vol.concat('(', issData.year, ')')
            const smItem = {
              route: { 
                //note search for -STUBB_CREATE_VOL_YEARS to see how this works
                //used to ensure separate router names defines to selection does 
                //not result in multiple menu items highlighted
                //also provides for deep link back to correct menu selection
                name: 'publication-archive-admin-'.concat(issData.vol),  
                params: { 
                  issn: this.publicationId, 
                  vol: issData.vol,
                },
              },
              title: volYear,
              action: 'read',
              resource: 'ACL',
            }
            //console.log('uuuuuuuuuddd', smItem)
            gvItem.children.push(smItem)
          })
          this.menuItems.push(gvItem)
        } else {
          this.menuItems.push(navItem)
        }
      }
      this.menuItemsPopulated = true
    },
    getPublication(id) {
      publicationApi.getPublication(id, this, null)
    },
    backFromGetPublication(serverData) {
      //console.log('33333333333333333333', serverData)
      if (serverData.error) {
        console.log('222222222 serverData.error', serverData.error)
      } else {
        if (!serverData.short_name) {
          console.log('444444444444444444444', serverData)
          this.$store.commit('pubmillGlobalStore/updateCurrentPublicationShortName', serverData.issn)
          this.publicationId = serverData.issn
          this.publicationShortName = serverData.short_name
        } else {
          console.log('55555555555555555', serverData)
          this.$store.commit('pubmillGlobalStore/updateCurrentPublicationName', serverData.name)
          this.$store.commit('pubmillGlobalStore/updateCurrentPublicationShortName', serverData.short_name)
          this.$store.commit('pubmillGlobalStore/updateCurrentPublicationIssueData', serverData)
          this.publicationId = serverData.issn
          this.publicationShortName = serverData.short_name
          this.publicationIssueData = serverData
        }
        this.pubmillOnPublicationNavRefresh()
      }
    },
  },
}
</script>
