<!--DL This is where logged in user is displayed in top right-->
<template>
  <div
    v-if="$store.state.dlStore.AppActiveUser.displayName || isManuscriptSubmission"
    class="navbar-container d-flex content align-items-center"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <b-row class="bookmark-wrapper flex-grow-1 d-lg-flex">
      <b-col cols="2">
        <!--Pubmill-Dependency here  change logo name or replace file-->
        <img class="h-8 logo_padding" src="@/assets/images/logo/logo2.png" alt="Logo">
        <!--<h1 class="vx-logo-text text-white"> Record </h1>-->
      </b-col>
      <b-col cols="1">
        <div v-if="primaryLoading">
          <b-spinner 
            variant="white"
            label="Loading..."
          />
        </div>
      </b-col>
      <b-col cols="6"> 
        <!--Pubmill-Dependency here  text changed to white and headerTitle calculated here-->
        <h4 class="vx-logo-text text-white">
          {{ headerTitle }}
        </h4>
      </b-col>
      <b-col v-if="$route.params.issn && $store.state.dlStore.AppActiveUser.displayName" cols="3"> 
        <!--Pubmill-Dependency here search button-->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.bottom="'Search'"
          variant="primary"
          class="btn-icon rounded-circle"
          @click.stop="gotoSearch()"
        >
          <feather-icon icon="SearchIcon"  size="20"/>
        </b-button>
      </b-col>
    </b-row>

    <b-navbar-nav v-if="$store.state.dlStore.AppActiveUser.displayName" class="nav align-items-center ml-auto">
      <!--<dark-Toggler class="d-none d-lg-block" />-->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
         
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ activeUserInfo.displayName }}
            </p>
            <span class="user-status">{{ userRole }}</span>
          </div>
         
          <b-avatar
            v-if="activeUserInfo.avatar"
            size="35"
            :src="activeUserInfo.avatar"
          />
          <b-avatar 
            v-else
            size="35"
            variant="secondary"
          >
            <feather-icon icon="UserIcon" />
          </b-avatar>
        </template>
        <!--
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>

        <b-dropdown-divider />
        -->
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import publicationApi from '@/dl_pubmill/apis/publication'
import {
  // BDropdownDivider
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BSpinner, BRow, BCol, BButton, VBTooltip
} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,

    // Navbar Components
    // DarkToggler,
    BSpinner,
    BRow, 
    BCol,
    BButton,
  },
  directives: {
    Ripple, 
    'b-tooltip': VBTooltip,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      headerTitle: null,
      primaryLoading: null,
      isManuscriptSubmission: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.dlStore.AppActiveUser
    },
    userRole() {
      if (!this.$store.state.dlStore.userRoles
        || !this.$store.state.pubmillGlobalStore.currentPublicationObject) {
        return ''
      }
      const { userRoles } = this.$store.state.dlStore
      if (userRoles.sa === 'y') {
        return 'SA'
      }
      const issn = this.$store.state.pubmillGlobalStore.currentPublicationObject.id
      const roleLevel = userRoles[issn]
      let returnStr = ''
      if (roleLevel === '20') {
        returnStr = 'Admin'
      }
      return returnStr
    },
     
    activeUserInitials() {
      const nodes = this.$store.state.dlStore.AppActiveUser.displayName.split(' ')
      let initials = nodes[0][0]
      if (nodes.length > 1) {
        initials += nodes[1][0]
      }
      return initials 
    },
  },
  watch: {
    '$store.state.pubmillGlobalStore.currentPublicationObject.name': {
      handler() {
        console.log('rrrrrrrrrcurrentPublicationObject.name', this.$store.state.pubmillGlobalStore.currentPublicationObject)
        if (this.$store.state.pubmillGlobalStore.currentPublicationObject.name == null
           && this.$store.state.pubmillGlobalStore.currentPublicationObject.id != null) {
          this.getPublication(this.$store.state.pubmillGlobalStore.currentPublicationObject.id)
        } else {
          this.refreshTitle()
        }
      },
    },
    '$store.state.pubmillGlobalStore.primaryLoading': {
      handler() {
        this.primaryLoading = this.$store.state.pubmillGlobalStore.primaryLoading
      },
    },
  },
  mounted() {
    if (window.location.href.includes('submission.versionofrecord.com')) {
      this.headerTitle = 'Manuscript Submission'
      this.isManuscriptSubmission = true
      return
    }
    if (this.$store.state.pubmillGlobalStore.currentPublicationObject) {
      this.refreshTitle()
    }
  },
  methods: {
    gotoSearch() {
      this.$router.push({ name: 'search', params: { issn: this.$route.params.issn, tab: 0 } })
    },
    logout() {
      this.$router.push({ name: 'dl-auth-logout' })
    },
    refreshTitle() {
      //console.log('NavBar refresh title route', this.$route.name, 'currentPublicationObject', this.$store.state.pubmillGlobalStore.currentPublicationObject)
      
      if (this.$route.name === 'my-publications' || this.$route.name === 'dl-auth-rd') {
        this.headerTitle = 'Publication Listing'
      } else if (this.$store.state.pubmillGlobalStore.currentPublicationObject.name) {
        this.headerTitle = this.$store.state.pubmillGlobalStore.currentPublicationObject.name
      }
    },
    getPublication(id) {
      publicationApi.getPublication(id, this, null)
    },
    backFromGetPublication(serverData) {
      //console.log('33333333333333333333', serverData)
      if (serverData.error) {
        console.log('222222222 serverData.error', serverData.error)
        return
      } 
      if (!serverData.short_name) {
        console.log('444444444444444444444', serverData)
        this.$store.commit('pubmillGlobalStore/updateCurrentPublicationShortName', serverData.issn)
        this.publicationId = serverData.issn
        this.publicationShortName = serverData.short_name
      } else {
        console.log('55555555555555555', serverData)
        this.$store.commit('pubmillGlobalStore/updateCurrentPublicationName', serverData.name)
        this.$store.commit('pubmillGlobalStore/updateCurrentPublicationShortName', serverData.short_name)
        this.$store.commit('pubmillGlobalStore/updateCurrentPublicationIssueData', serverData)
        this.publicationId = serverData.issn
        this.publicationShortName = serverData.short_name
        this.publicationIssueData = serverData
      }
    },
  },
} 
</script>
//Athenable-Dependency here
<style lang="scss">
.logo_padding111 {
  padding-right: 8px;
}
.h-8 {
    height: 2rem !important;
}
</style>
